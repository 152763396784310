/**
 * External Dependencies
 */

import './blocks/fullpage.js'
import './blocks/posts.js'

$(document).ready(() => {
  // Fullpage
  // console.log('Fullpage Start');
  // $('#fullpage').fullpage({
  //   licenseKey: 'A0B8649E-319B48DA-AB4F35FD-254F9D81',
  //   dragAndMove: false,
  //   scrollOverflow: true,
  //   controlArrows: true,
  //   responsiveHeight: 0,
  // });
  //
  // let headerheight = $('#wrapper-navbar').height();
  // $('.fp-content-bottom').css({"margin-bottom": headerheight});
  // console.log('headerheight', headerheight);

});
